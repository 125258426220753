import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ласкаво просимо до MetroVille
			</title>
			<meta name={"description"} content={"Ваші ворота в міське та приміське життя – MetroVille, де кожна нерухомість розповідає свою історію"} />
			<meta property={"og:title"} content={"Ласкаво просимо до MetroVille"} />
			<meta property={"og:description"} content={"Ваші ворота в міське та приміське життя – MetroVille, де кожна нерухомість розповідає свою історію"} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text1" />
		</Components.Header>
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-4.jpg?v=2024-02-09T09:45:45.559Z) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Ласкаво просимо до MetroVille
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Ваші ворота в міське та приміське життя – MetroVille Realty, де кожна нерухомість розповідає свою історію
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Контакти
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-4" background="linear-gradient(180deg,--color-lightD2 0%,transparent 100%) 0 0 no-repeat">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="50%"
					font="normal 500 52px/1.2 --fontFamily-serifTimes"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					Відчуйте найкраще від міського та приміського життя з MetroVille.
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="#727a82" sm-margin="0px 0px 25px 0px">
						Від стильних міських квартир до чарівних таунхаусів і просторих будинків у передмісті, ми спеціалізуємося на підборі для вас нерухомості, яка відображатиме ваш стиль життя та прагнення. Дозвольте MetroVille Realty провести вас крізь різноманітні ландшафти сучасного життя, де кожна вулиця та квартал обіцяють новий початок.
					</Text>
					<Box min-width="10px" min-height="10px" display="flex" sm-flex-direction="column">
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							margin="0px 25px 0px 0px"
							sm-margin="0px 0 10px 0px"
						>
							<Link href="tel:0969519103" color="#000000" text-decoration-line="initial" font="--lead">
								0969519103
							</Link>
						</Box>
						<Box min-width="10px" min-height="10px" display="flex" margin="0px 0 0px 0px">
							<Link href="mailto:hello@luminesto.com" color="#000000" text-decoration-line="initial" font="--lead">
								hello@luminesto.com
							</Link>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="Price-17">
			<Text margin="0px 0px 20px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="--dark">
				Вирушайте у подорож відкриттів разом з MetroVille.
			</Text>
			<Text margin="0px 50% 20px 0px" font="normal 900 48px/1.2 --fontFamily-serifGaramond" lg-margin="0px 0 20px 0px">
				Відкрийте для себе MetroVille
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://images.unsplash.com/photo-1523217582562-09d0def993a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
						srcSet="https://images.unsplash.com/photo-1523217582562-09d0def993a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1523217582562-09d0def993a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1523217582562-09d0def993a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1523217582562-09d0def993a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1523217582562-09d0def993a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1523217582562-09d0def993a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1523217582562-09d0def993a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Різноманітний вибір
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="--dark">
						MetroVille Realty пропонує широкий вибір варіантів для задоволення ваших унікальних потреб і способу життя.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://images.unsplash.com/photo-1590650516494-0c8e4a4dd67e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
						object-position="right"
						srcSet="https://images.unsplash.com/photo-1590650516494-0c8e4a4dd67e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1590650516494-0c8e4a4dd67e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1590650516494-0c8e4a4dd67e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1590650516494-0c8e4a4dd67e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1590650516494-0c8e4a4dd67e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1590650516494-0c8e4a4dd67e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1590650516494-0c8e4a4dd67e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Експертне керівництво
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="--dark">
						Наша команда досвідчених професіоналів з нерухомості тут, щоб надати індивідуальні рекомендації та підтримку, забезпечуючи безпроблемну та безпроблемну купівлю чи продаж житла.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://images.unsplash.com/photo-1480074568708-e7b720bb3f09?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
						srcSet="https://images.unsplash.com/photo-1480074568708-e7b720bb3f09?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1480074568708-e7b720bb3f09?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1480074568708-e7b720bb3f09?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1480074568708-e7b720bb3f09?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1480074568708-e7b720bb3f09?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1480074568708-e7b720bb3f09?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1480074568708-e7b720bb3f09?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Місцева інформація
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="--dark">
						Використовуючи наші глибокі знання про місцевий ринок і околиці, ви зможете приймати обґрунтовані рішення та знаходити ідеальну громаду, де ви почуватиметеся як удома.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-4" background="linear-gradient(90deg,--color-lightD2 0%,transparent 100%) 0 0 no-repeat">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="50%"
					font="normal 500 52px/1.2 --fontFamily-serifTimes"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					Персоналізовані рішення
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="#727a82" sm-margin="0px 0px 25px 0px">
						Незалежно від того, чи ви купуєте нерухомість вперше, чи є досвідченим інвестором, чи плануєте продати свою нерухомість, MetroVille Realty пропонує індивідуальні рішення для досягнення ваших конкретних цілей і завдань.
					</Text>
					<Box min-width="10px" min-height="10px" display="flex" sm-flex-direction="column">
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							margin="0px 25px 0px 0px"
							sm-margin="0px 0 10px 0px"
						>
							<Link href="tel:0969519103" color="#000000" text-decoration-line="initial" font="--lead">
								0969519103
							</Link>
						</Box>
						<Box min-width="10px" min-height="10px" display="flex" margin="0px 0 0px 0px">
							<Link href="mailto:hello@luminesto.com" color="#000000" text-decoration-line="initial" font="--lead">
								hello@luminesto.com
							</Link>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section text-align="center" padding="80px 0" sm-padding="40px 0">
			<Text as="h1" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
				MetroVille{" "}
				<br />
				Де міська енергія{" "}
				<br />
				зустрічається з передмістям
			</Text>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px">
					<Image
						src="https://images.unsplash.com/photo-1589834390005-5d4fb9bf3d32?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
						width="320px"
						max-width="100%"
						srcSet="https://images.unsplash.com/photo-1589834390005-5d4fb9bf3d32?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1589834390005-5d4fb9bf3d32?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1589834390005-5d4fb9bf3d32?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1589834390005-5d4fb9bf3d32?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1589834390005-5d4fb9bf3d32?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1589834390005-5d4fb9bf3d32?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1589834390005-5d4fb9bf3d32?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box padding="10px">
					<Image
						src="https://uploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-3.jpg?v=2024-02-09T09:45:45.542Z"
						width="320px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-3.jpg?v=2024-02-09T09%3A45%3A45.542Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-3.jpg?v=2024-02-09T09%3A45%3A45.542Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-3.jpg?v=2024-02-09T09%3A45%3A45.542Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-3.jpg?v=2024-02-09T09%3A45%3A45.542Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-3.jpg?v=2024-02-09T09%3A45%3A45.542Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-3.jpg?v=2024-02-09T09%3A45%3A45.542Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-3.jpg?v=2024-02-09T09%3A45%3A45.542Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box padding="10px">
					<Image
						src="https://uploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-1.jpg?v=2024-02-09T09:45:45.546Z"
						width="320px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-1.jpg?v=2024-02-09T09%3A45%3A45.546Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-1.jpg?v=2024-02-09T09%3A45%3A45.546Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-1.jpg?v=2024-02-09T09%3A45%3A45.546Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-1.jpg?v=2024-02-09T09%3A45%3A45.546Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-1.jpg?v=2024-02-09T09%3A45%3A45.546Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-1.jpg?v=2024-02-09T09%3A45%3A45.546Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-1.jpg?v=2024-02-09T09%3A45%3A45.546Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link4" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65c4dc341d712a00232fcf5b"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});